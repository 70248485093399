@charset "utf-8";

/* Google Fonts */
@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,400italic,700%7CPT+Sans:400);

/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        // path {
        //     fill: $grey-color;
        // }
    }
}

@import
    "poole",
    "syntax",
    "lanyon"
;
